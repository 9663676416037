import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
	standalone: true,
	imports: [MatToolbar, MatIcon, MatIconButton],
})
export class PageNotFoundComponent {
	constructor(
		private router: Router,
		private location: Location
	) {}

	goHome() {
		this.router.navigate(['/home']);
	}

	toolbarIconClick() {
		this.location.back();
	}
}
