import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InSequenceGuard {
	constructor(private injector: Injector) {}

	canActivate(nextRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const guardsInjectors: InjectionToken<{
			canActivate: CanActivateFn;
		}>[] = nextRoute.data.inSequenceGuard;

		if (!guardsInjectors || !guardsInjectors.length) {
			console.error('Please provide inSequenceGuard array in route Data');
			return of(false);
		}

		let inSequenceCanActivate$: Observable<boolean> = of(true);

		for (const guard of guardsInjectors) {
			const routeGuard = this.injector.get(guard);
			const routeGuardRes$: Observable<boolean> = routeGuard.canActivate(nextRoute, state) as Observable<boolean>;
			inSequenceCanActivate$ = inSequenceCanActivate$.pipe(flatMap((bool) => (bool ? routeGuardRes$ : of(false))));
		}

		return inSequenceCanActivate$;
	}
}
